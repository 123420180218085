.law-nav {
  width: max-content;
  padding: 1rem 2rem;
  align-items: center;
  background-color: red;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  transition: all 0.4s;
  font-size: 1.75rem;
}

.law-card {
  width: 100%;
  //   max-width: calc(100% - 11.5rem);
  width: 100%;
  margin: auto;
  padding: 2.4rem 0;
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.25rem;
  border-radius: 1rem;

  //   @include screenSize(S) {
  //     max-width: calc(100% - 5rem);
  //   }

  &__info {
    padding: 1rem 2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;

    @include responsiveness(lawinfo) {
      flex-direction: column;
    }

    @include responsiveness(lawinfoCol) {
      flex-direction: column;
    }

    .law-desc {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      &__title {
        font-size: 1.7rem;
      }

      &__intro {
        font-size: 1.2rem;
        overflow: hidden;
        @include text_truncate(3);
      }
    }
  }

  &__copies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.25rem;
  }

  .copy {
    padding: 1.2rem;
    display: grid;
    align-items: center;
    gap: 2rem;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #f5f8fb;
    border-radius: 8px;

    &__top {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 16px;
      border-bottom: solid 1px #2f67e96c;
      padding-bottom: 1rem;
    }

    &__value {
      width: 100%;
      font-size: 16px;
      text-align: center;
      background-color: #2f68e933;
      color: #0447e0;
      border-radius: 8px;
      padding: 10px;
    }
  }

  .icon {
    width: max-content;
    height: max-content;
    padding: 1.5rem;
    display: flex;
    border-radius: 50%;
    background-color: $color-green;

    img {
      object-fit: none;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .label {
    font-size: 1.4rem;
  }
  .value {
    font-size: 2rem;
    font-weight: 700;
  }
}

.sales {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1em;

  &__card {
    padding: 2rem 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    background-color: $color-bg-blue-2;
    border-radius: 1rem;

    .card-icon {
      width: max-content;
      height: max-content;
      padding: 1rem;
      display: flex;
      border-radius: 50%;

      &--green {
        background: $color-green;
      }
      &--pink {
        background: $color-pink;
      }
      &--blue {
        background: $color-blue-1;
      }

      &--orange {
        background: $color-orange-1;
      }

      img {
        object-fit: none;
      }
    }
    .card-label {
      font-size: 1.4rem;
      color: $color-text-gray-1;
    }
    .card-value {
      font-size: 2rem;
      font-weight: 500;
    }
    .card-text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.chart {
  width: 100%;
  padding: 10rem 10%;
  background-color: #fff;
  // max-width: calc(100% - 11.5rem);
  // margin: auto;

  // @include screenSize(S) {
  // 	width: calc(100% - 5rem);
  // }
}
